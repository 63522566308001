import siteIcon from '../../public/favicon.png';
import siteLogo from '../../public/images/Plexus_Innovation_Logo.png';

export default {
  siteName: 'PLEXUS',
  siteIcon: siteIcon,
  siteLogo: siteLogo,
  footerText: `Powered By Plexus Innovation Ltd`,
  enableAnimatedRoute: false,
  apiUrl: 'https://plexusdashboardnew-be.azurewebsites.net/',
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1'
  },
  dashboard: '/dashboard'
};
