const actions = {
  INC: 'INC',
  DEC: 'DEC',

  inc: () => {
    return { type: actions.INC };
  },

  dec() {
    return { type: actions.DEC };
  },
};

export default actions;
