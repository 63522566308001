import axios from 'axios';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import AppProvider from './AppProvider';
import siteConfig from './config/site.config';
import { persistor, store } from './redux/store';
import whiteLabelingActions from './redux/white-labling/actions';
import Routes from './router';
import httpService from './shared/library/helpers/interceptors';
import setAuthorizationToken from './shared/library/helpers/setAuthorizationToken';
import GlobalStyles from './shared/styles/globalStyle';

axios.defaults.baseURL = siteConfig.apiUrl;

const App = () => {
  useEffect(() => {
    // const accessToken = loadState('accessToken');
    const accessToken = store.getState().Auth?.accessToken;
    setAuthorizationToken(accessToken);
    httpService.interceptor(store);
    store.dispatch(whiteLabelingActions.getWhiteLabeling(window.location.host));
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppProvider>
          <>
            <GlobalStyles />
            <Routes />
          </>
        </AppProvider>
      </PersistGate>
    </Provider>
  );
};

// Boot()
//   .then(() => App())
//   .catch(error => console.error(error));

export default App;
