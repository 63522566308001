import axios from 'axios';

const actions = {
  WHITE_LABELING: "WHITE_LABELING",
  getWhiteLabeling: (domain) => {
    return (dispatch) => {
      return axios.get(`./white-lablelling?Domain=${domain}`).then((resp) => {
        dispatch({ type: actions.WHITE_LABELING, data: resp.data });
      });
    };
  },
};

export default actions;
