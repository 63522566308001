import actions from './actions';

const initState = { keySearch: '' };

export default function alertEventReducer(state = initState, action) {
  switch (action.type) {
    case actions.ALERT_EVENT_SEARCH:
      return {
        ...state,
        keySearch: action.data,
      };
    default:
      return state;
  }
}
