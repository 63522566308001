import { combineReducers } from 'redux';

import App from './app/reducer';
import Articles from './articles/reducers';
import Auth from './auth/reducer';
import Box from './box/reducer';
import Calendar from './calendar/reducer';
import Cards from './card/reducer';
import Chat from './chat/reducers';
import Contacts from './contacts/reducer';
import Dashboard from './dashboard/reducer';
import drawer from './drawer/reducer';
import DynamicChartComponent from './dynamicEchart/reducer';
import Ecommerce from './ecommerce/reducer';
import githubSearch from './githubSearch/reducers';
import Investors from './investors/reducers';
import Invoices from './invoice/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
import Loading from './loading/reducer';
import Mails from './mail/reducer';
import modal from './modal/reducer';
import Notes from './notes/reducer';
import profile from './profile/reducer';
import scrumBoard from './scrumBoard/reducer';
import SiteDashboard from './siteDashboard/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';
import Todos from './todos/reducer';
import WhiteLabeling from './white-labling/reducer';
import YoutubeSearch from './youtubeSearch/reducers';

import AlertEvent from './alert-events/reducer';
export default combineReducers({
  Auth,
  App,
  SiteDashboard,
  WhiteLabeling,
  // Companies,
  ThemeSwitcher,
  LanguageSwitcher,
  Mails,
  Calendar,
  Box,
  Notes,
  Todos,
  Contacts,
  Cards,
  Chat,
  DynamicChartComponent,
  Ecommerce,
  Invoices,
  YoutubeSearch,
  Articles,
  Investors,
  scrumBoard,
  modal,
  drawer,
  profile,
  githubSearch,
  Dashboard,
  Loading,
  AlertEvent
});
