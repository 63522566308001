import actions from './actions';

const initState = {
  data: [],
  type: null,
};

export default function siteDashboardReducer(state = initState, action) {
  switch (action.type) {
    case actions.EXPORT_DATA:
      return {
        data: action.data.data,
        type: action.data.type,
      };

    case actions.CLEAR_EXPORT_DATA:
      return initState;

    default:
      return state;
  }
}
