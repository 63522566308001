import axios from 'axios';

const baseUrl = '/api/site-dashboard';

const actions = {
  EXPORT_DATA: 'EXPORT_DATA',
  CLEAR_EXPORT_DATA: 'CLEAR_EXPORT_DATA',

  getSiteDashboardBasicInfo: siteId => () => {
    return axios.get(`${baseUrl}/${siteId}`);
  },

  getSubSitesBySiteId: siteId => () => {
    return axios.get(`${baseUrl}/sub-sites/${siteId}`);
  },

  getNodes: siteId => () => {
    return axios.get(`${baseUrl}/${siteId}/nodes`);
  },

  getHubs: siteId => () => {
    return axios.get(`${baseUrl}/${siteId}/hubs`);
  },

  reading: (siteId, dateRangeModel) => () => {
    return axios.post(`${baseUrl}/${siteId}/readings`, dateRangeModel);
  },

  readingMinMax: (siteId, dateRangeModel) => () => {
    return axios.post(`${baseUrl}/${siteId}/readings-minmax`, dateRangeModel);
  },

  medicationFridgeReports: siteId => () => {
    return axios.get(`${baseUrl}/${siteId}/medication-fridge-reports`);
  },

  exportReading: (siteId, model) => () => {
    return axios.post(`${baseUrl}/${siteId}/export/readings`, model);
  },

  exportReadingMinMax: (siteId, model) => () => {
    return axios.post(`${baseUrl}/${siteId}/export/readings-minmax`, model);
  },
  checkPermission : siteId => {
    return dispatch => {
      return axios.get(`${baseUrl}/check-permission/${siteId}`);
    };
  }
};

export default actions;
