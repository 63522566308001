import actions from './actions';
import authActions from '../auth/actions';

const initState = { data: null, nodes: [], loading: false };

export default function dashboardReducer(state = initState, action) {
  switch (action.type) {
    case actions.DASHBOARD_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case actions.DASHBOARD_GET_NODES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.DASHBOARD_GET_NODES_SUCCESS:
      return {
        ...state,
        loading: false,
        nodes: action.data,
      };
    case actions.DASHBOARD_ERROR:
      return initState;
    case authActions.LOGIN_SUCCESS:
      return initState;
    default:
      return state;
  }
}
