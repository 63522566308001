import actions from './actions';

const initState = {
  faviconUrl: "",
  loginLogoUrl: "",
  siteLogoUrl: "",
  websiteTitle: "",
};

export default function whiteLabelingReducer(state = initState, action) {
  switch (action.type) {
    case actions.WHITE_LABELING:
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
}
