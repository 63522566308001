import actions from './actions';

const initState = { count: 0 };

export default function loadingReducer(state = initState, action) {
  switch (action.type) {
    case actions.INC:
      return {
        count: ++state.count,
      };
    case actions.DEC:
      return {
        count: --state.count < 0 ? 0 : state.count,
      };
    default:
      return state;
  }
}
