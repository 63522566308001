export const FormItemDecorator = {
  validateTrigger: ['onChange', 'onBlur']
};

export const CheckboxFormItemDecorator = {
  ...FormItemDecorator,
  valuePropName: 'checked'
};

export const RangePickerFormItemDecorator = {
  ...FormItemDecorator,
  normalize: (value, prevValue, allValues) => {
    if (!value) {
      return prevValue;
    }

    if (typeof value === 'string') {
      return [];
    }

    return value;
  }
};

export const DebouceTime = 250;

export const RouteKeys = {
  DASHBOARD: 'item_0',
  PLEXUS: 'plexus',
  MANAGEMENT: 'management',
  COMPANIES: 'companies'
};

export const AggregatorType = {
  VALUE_MIN: 0,
  VALUE_MAX: 1,
  AVERAGE_MIN_MAX: 2,
  AVERAGE_MIN: 3,
  AVERAGE_MAX: 4,
  VALUE_MIN_MAX: 5,
  VALUE_MAX_MIN: 6
};

export const PurseLogger = {
  RAW_DATA: 'RawData',
  CONSUMPTINO_OVERTIME: 'ConsumptionOverTime',
  CONSUMPTINO_PERID: 'ConsumptionPeriod'
};

export const ChartType = {
  RAW_DATA: 'RawData',
  CONSUMPTINO_OVERTIME: 'ConsumptionOverTime',
  CONSUMPTINO_PERID: 'ConsumptionPeriod',
  READING: 'Reading',
  MINMAX: 'MinMax'
};

export const PurseLoggerText = 'Pulse Logger';

export const ConsumptionPeriod = {
  FIFTEEN_MINUTES: '15minutes',
  HALF_HOUR: '30minutes',
  ONE_HOUR: '1hour',
  QUARTER_DAY: '6hours',
  HALF_DAY: '12hours',
  ONE_DAY: '24hours'
};

export const PurseLoggerArray = [
  { value: ChartType.RAW_DATA, text: 'Raw Data' },
  { value: ChartType.CONSUMPTINO_OVERTIME, text: 'Consumption over time' },
  { value: ChartType.CONSUMPTINO_PERID, text: 'Consumption period' }
];

export const ConsumptionPeriodArray = [
  { value: ConsumptionPeriod.FIFTEEN_MINUTES, text: '15 minutes' },
  { value: ConsumptionPeriod.HALF_HOUR, text: '30 minutes' },
  { value: ConsumptionPeriod.ONE_HOUR, text: '1 hour' },
  { value: ConsumptionPeriod.QUARTER_DAY, text: '6 hours' },
  { value: ConsumptionPeriod.HALF_DAY, text: '12 hours' },
  { value: ConsumptionPeriod.ONE_DAY, text: '24 hours' }
];

export const ManufactureOption = ['UKE', 'Mackwell', 'EPS'];

export const MessageType = {
  EXCEPTION_SYSTEM: 'ExceptionSystem',
  ACCOUNT: 'Account',
  ALERT: 'Alert',
  REPORT: 'Report'
}
export const MessageStatus = {
  PENDING : 'Pending',
  SENT : 'Sent',
  ERROR : 'Error',
}

export const RegExp = {
  UPPERCASE: /(?=.*?[A-Z])/,
  LOWERCASE:/(?=.*?[a-z])/,
  DIGITS: /(?=.*?[0-9])/
} 
