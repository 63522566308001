import axios from 'axios';

const baseUrl = '/api/alertevents';

const alertEventActions = {

  ALERT_EVENT_SEARCH: 'ALERT_EVENT_SEARCH',

  getNodeAlertData: id => dispatch => {
    return axios.get(`${baseUrl}/${id}`);
  },
  getById: id => dispatch => {
    return axios.get(`${baseUrl}/detail/${id}`);
  },
  update: (id, model) => dispatch => {
    return axios.put(`${baseUrl}/detail/${id}`, model);
  },

  updateKeySearch: (text) => ({
    type: alertEventActions.ALERT_EVENT_SEARCH,
    data: text,
  })
};

export default alertEventActions;
