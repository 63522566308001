import { createBrowserHistory } from 'history';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { PUBLIC_ROUTE } from 'src/route.constants';

import { clearToken, getUserToken } from '../../shared/library/helpers/utility';
import actions from './actions';

const history = createBrowserHistory();
const fakeApiCall = true; // auth0 or express JWT

export function* loginRequest() {
  // yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
  //   const { token } = payload;
  //   if (token) {
  //     yield put({
  //       type: actions.LOGIN_SUCCESS,
  //       token: token,
  //       profile: 'Profile',
  //     });
  //   } else {
  //     if (fakeApiCall) {
  //       yield put({
  //         type: actions.LOGIN_SUCCESS,
  //         token: 'secret token',
  //         profile: 'Profile',
  //       });
  //     } else {
  //       yield put({ type: actions.LOGIN_ERROR });
  //     }
  //   }
  // });
}

export function* loginSuccess() {
  // yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
  //   yield localStorage.setItem('id_token', payload.token);
  // });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    yield clearToken();
    history.push(PUBLIC_ROUTE.SIGN_IN);
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getUserToken().get('idToken');
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: 'Profile',
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ]);
}
