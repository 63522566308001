// localStorage.js
export const loadState = key => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const clearState = () => {
  localStorage.clear();
};

// export const saveState = (key, value) => {
//   try {
//     const serializedState = JSON.stringify(value);
//     localStorage.setItem(key, serializedState);
//   } catch {
//     // ignore write errors
//   }
// };

export const saveState = (key, value, companyId = null) => {
  try {
    if (companyId) {
      let item = { [companyId]: value };
      const serializedState = JSON.stringify(item);
      localStorage.setItem(key, serializedState);
    } else {
      const serializedState = JSON.stringify(value);
      localStorage.setItem(key, serializedState);
    }
  } catch {
    // ignore write errors
  }
};
