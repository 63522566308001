import axios from 'axios';
import { PUBLIC_ROUTE } from 'src/route.constants';

import { notification } from '../../../components';
import authAction from '../../../redux/auth/actions';
import loadingAction from '../../../redux/loading/actions';
import history from '../../../shared/library/helpers/history';
import setAuthorizationToken from './setAuthorizationToken';

export default {
  interceptor: store => {
    axios.interceptors.request.use(
      function (config) {
        if (config.headers?.isIgnoredLoading) {
          return config;
        }

        store.dispatch(loadingAction.inc());
        return config;
      },

      function (error) {
        store.dispatch(loadingAction.dec());
        return Promise.reject(error);
      }
    );

    // Update: 28/1/2022
    // Check if there are many apis have unAuthorization errors,
    // show only 1 controlled by authorizationErrorTimes flag
    axios.interceptors.response.use(
      function (response) {
        localStorage.setItem("authorizationErrorTimes", "0");
        if (response.config.headers?.isIgnoredLoading) {
          return response.data;
        }
        store.dispatch(loadingAction.dec());
        return response.data;
      },
      function (error) {
        store.dispatch(loadingAction.dec());
        switch (error.response?.status) {
          case 401:
            let authorizationErrorTimes = Number(localStorage.getItem("authorizationErrorTimes"));
            authorizationErrorTimes = authorizationErrorTimes + 1;
            localStorage.setItem("authorizationErrorTimes", authorizationErrorTimes.toString());
            store.dispatch({ type: authAction.LOGOUT });
            if (authorizationErrorTimes > 1) break;
          case 403:
            setAuthorizationToken(null);
            notification(
              'error',
              error.response?.data.message || error.message
            );
            store.dispatch({ type: authAction.LOGOUT });
            break;

          default:
            if ((error.response?.data.message || error.message) == 'Network Error' &&
              window.location.pathname != PUBLIC_ROUTE.PAGE_MAINTENANCE) {
              history.push(PUBLIC_ROUTE.PAGE_MAINTENANCE);
            } else {
              notification(
                'error',
                error.response?.data.message || error.message
              );
            }
            break;
        }
        return Promise.reject(error);
      }
    );
  },
};
