import axios from 'axios';
import isIgnoredLoading from 'src/shared/library/helpers/isIgnoredLoading';

const actions = {
  DASHBOARD_SUCCESS: 'DASHBOARD_SUCCESS',
  DASHBOARD_GET_NODES_SUCCESS: 'DASHBOARD_GET_NODES_SUCCESS',
  DASHBOARD_GET_ALERT_EVENTS_SUCCESS: 'DASHBOARD_GET_ALERT_EVENTS_SUCCESS',
  DASHBOARD_ERROR: 'DASHBOARD_ERROR',
  DASHBOARD_GET_NODES_REQUEST: 'DASHBOARD_GET_NODES_REQUEST',

  getDashboardData: () => {
    return dispatch => {
      axios
        .get('/api/dashboard')
        .then(resp => {
          dispatch(success(resp.data));
        })
        .catch(err => {
          dispatch(error(err.message));
        });
    };

    function success(data) {
      return { type: actions.DASHBOARD_SUCCESS, data };
    }
    function error(message) {
      return { type: actions.DASHBOARD_ERROR, message };
    }
  },

  getDashboardDataById: (companyId) => {
    return dispatch => {
      return axios.get(`/api/dashboard/company/${companyId}`);
    };
  },

  getNodesBySiteId: siteId => {
    return dispatch => {
      dispatch({ type: actions.DASHBOARD_GET_NODES_REQUEST });
      return axios.get(`/api/dashboard/${siteId}/nodes`, isIgnoredLoading(true)).then(resp => {
        dispatch({
          type: actions.DASHBOARD_GET_NODES_SUCCESS,
          data: resp.data
        });
      });
    };
  },

  getAlertEvents: params => {
    return dispatch => {
      return axios.get('/api/alertevents', { params });
    };
  }
};

export default actions;
