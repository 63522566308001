import moment from 'moment';
import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import createEncryptor from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import authActions from './auth/actions';
import rootReducer from './root-reducer';
import rootSaga from './root-saga';

const jwtMiddleware = ({ dispatch, getState }) => {
  return (next) => (action) => {
    // only worry about expiring token for async actions
    if (typeof action === "function") {
      const {
        loggedTime,
        expiresIn,
        refreshToken,
        companyId,
        freshTokenPromise,
      } = getState().Auth || {};
      let isExpired =
        moment(loggedTime).add(expiresIn, "seconds") - moment() < 5000; // // Call get new token before 10s

      if (refreshToken && isExpired) {
        if (!freshTokenPromise) {
          return authActions
            .refreshToken(dispatch, refreshToken, companyId)
            .then(() => next(action));
        } else {
          return freshTokenPromise.then(() => next(action));
        }
      }
    }

    return next(action);
  };
};

const sagaMiddleware = createSagaMiddleware();
const middlewares = [jwtMiddleware, thunk, sagaMiddleware];

const encryptor = createEncryptor({
  secretKey: "my-super-secret-key",
  onError: function(error) {
    // Handle the error.
  },
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["Auth", "SiteDashboard", "WhiteLabelingReducer"],
  // blacklist: ['Dashboard'],
  // transforms: [encryptor]
};

if (process.env.NODE_ENV === "production") {
  persistConfig.transforms = [encryptor];
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== "production") {
    const { composeWithDevTools } = require("redux-devtools-extension");
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const store = createStore(persistedReducer, bindMiddleware(middlewares));
sagaMiddleware.run(rootSaga);
const persistor = persistStore(store);
export { store, persistor };
