import axios from 'axios';
import setAuthorizationToken from '../../shared/library/helpers/setAuthorizationToken';
import appActions from '../../redux/app/actions';

const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  MULTI_FACTOR_LOGIN_REQUEST: 'MULTI_FACTOR_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  UPDATE_USERINFO: 'UPDATE_USERINFO',
  SWITCH_ACCESS_TOKEN: 'SWITCH_ACCESS_TOKEN',
  REFRESHING_TOKEN: 'REFRESHING_TOKEN',
  DONE_REFRESHING_TOKEN: 'DONE_REFRESHING_TOKEN',

  // checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),

  updateUserInfo: data => {
    return { type: actions.UPDATE_USERINFO, data };
  },

  changePassword: model => () => {
    return axios.post('/api/change-password', model);
  },

  forgotPassword: email => () => {
    return axios.post('/api/forgot-password', email);
  },

  verifyToken: token => () => {
    return axios.post('/api/forgot-password/verify-token', { token });
  },

  resetPassword: model => () => {
    return axios.post('/api/reset-password', model);
  },

  login: model => {
    return dispatch => {
      return axios.post('/api/login', model).then(resp => {
        const data = resp.data || {};
        if (data.requireMFV) {
          dispatch({ type: actions.MULTI_FACTOR_LOGIN_REQUEST, data });
        } else {
          setAuthorizationToken(data.accessToken);
          data.loggedTime = new Date().toISOString();
          dispatch({ type: actions.LOGIN_SUCCESS, data });
        }
      });
    };
  },

  multiFactorVerify: model => {
    return dispatch => {
      return axios.post('/api/verify-opt', model).then(resp => {
        const data = resp.data || {};
        setAuthorizationToken(data.accessToken);
        data.loggedTime = new Date().toISOString();
        dispatch({ type: actions.LOGIN_SUCCESS, data });
      })
    }
  },

  resendOTP: model => () => { 
    return axios.post('/api/resend-opt', model); 
  },

  switchCompany: companyId => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        const switchAccessToken = (data, dispatch) => {
          setAuthorizationToken(data.accessToken);
          dispatch({ type: actions.SWITCH_ACCESS_TOKEN, data });
          resolve(true);
        };

        const userToken = (getState().Auth.userTokens || {})[companyId];
        if (userToken) {
          switchAccessToken(userToken, dispatch);
        } else {
          axios.post(`/api/switch-company`, { companyId }).then(resp => {
            resp.data.loggedTime = new Date().toISOString();
            switchAccessToken(resp.data, dispatch);
          });
        }
      });
    };
  },

  refreshToken: (dispatch, refreshToken, companyId) => {
    var freshTokenPromise = axios
      .post(`/api/refresh-token`, { refreshToken, companyId })
      .then(resp => {
        let data = resp.data;
        data.loggedTime = new Date().toISOString();

        setAuthorizationToken(data.accessToken);
        dispatch({ type: actions.SWITCH_ACCESS_TOKEN, data });

        dispatch({ type: actions.DONE_REFRESHING_TOKEN });

        return Promise.resolve(resp.data);
      })
      .catch(err => {
        dispatch({ type: actions.DONE_REFRESHING_TOKEN });
        return Promise.reject(err);
      });

    dispatch({ type: actions.REFRESHING_TOKEN, freshTokenPromise });

    return freshTokenPromise;
  },

  shadowCompany: companyId => {
    return dispatch => {
      return axios.post('/api/shadow-company', { companyId }).then(resp => {
        const data = resp.data || {};
        setAuthorizationToken(data.accessToken);
        data.loggedTime = new Date().toISOString();
        dispatch({ type: actions.LOGIN_SUCCESS, data });
      });
    };
  },

  shadowUser: userId => {
    return dispatch => {
      return axios.post('/api/shadow-user', { userId }).then(resp => {
        const data = resp.data || {};
        setAuthorizationToken(data.accessToken);
        data.loggedTime = new Date().toISOString();
        dispatch({ type: actions.LOGIN_SUCCESS, data });
      });
    };
  },

  exitShadow: () => {
    return dispatch => {
      return axios.post('/api/exit-shadow', {}).then(resp => {
        const data = resp.data || {};
        setAuthorizationToken(data.accessToken);
        data.loggedTime = new Date().toISOString();
        data.companyId = null;
        dispatch({ type: actions.LOGIN_SUCCESS, data });
      });
    };
  },

  logout: () => {
    return dispatch => {
      return axios.post('/api/logout', {}).then(() => {
        dispatch({ type: actions.LOGOUT });
        dispatch(appActions.clearMenu());
      });
    };
  },
};

export default actions;
