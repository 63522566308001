import actions from './actions';

const initState = {
  accessToken: null,
  tokenType: '',
  expiresIn: 0,
  role: null,
  companyId: null,
  loggedTime: null,
  refreshToken: null,
  isShadow: false,
  basicInfo: {},
  userTokens: {},
  multiFactorVerify: {}
};

export default function authReducer(state = initState, action) {
  let userTokens = {};
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      let session = { ...action.data };
      delete session.refreshToken;
      delete session.basicInfo;
      delete session.isShadow;
      userTokens[session.companyId] = session;
      return {
        ...initState,
        ...action.data,
        userTokens,
      };
    case actions.MULTI_FACTOR_LOGIN_REQUEST:
      return {
        ...initState,
        multiFactorVerify: action.data,
      }
    case actions.SWITCH_ACCESS_TOKEN:
      userTokens = state.userTokens || {};
      userTokens[action.data.companyId] = action.data;
      return {
        ...state,
        ...action.data,
        userTokens,
      };
    case actions.UPDATE_USERINFO:
      return {
        ...state,
        basicInfo: {
          ...state.basicInfo,
          ...action.data,
        },
      };
    case actions.REFRESHING_TOKEN:
      return {
        ...state,
        freshTokenPromise: null,
      };
    case actions.DONE_REFRESHING_TOKEN:
      return {
        ...state,
        freshTokenPromise: action.freshTokenPromise,
      };

    case actions.LOGOUT:
      return {};
    default:
      return state;
  }
}
