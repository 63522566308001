import React, { lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import faviIcon from '../public/favicon.png';
import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';

const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: () => <Redirect to={PUBLIC_ROUTE.SIGN_IN} />,
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import("./containers/Pages/500/500")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_MAINTENANCE,
    component: lazy(() => import("./containers/Pages/maintenance/maintenance")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import("./containers/Pages/SignIn/SignIn")),
  },
  {
    path: PUBLIC_ROUTE.MULTI_FACTOR,
    component: lazy(() => import("./containers/Pages/MultiFactorVerify/MultiFactorVerify")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import("./containers/Pages/SignUp/SignUp")),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() =>
      import("./containers/Pages/ForgotPassword/ForgotPassword")
    ),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() =>
      import("./containers/Pages/ResetPassword/ResetPassword")
    ),
  },
  {
    path: PUBLIC_ROUTE.AUTH0_CALLBACK,
    component: lazy(() =>
      import("./containers/Authentication/Auth0/Auth0Callback")
    ),
  },
  {
    path: `${PUBLIC_ROUTE.EXPORTDATA}/:id`,
    component: lazy(() => import("./containers/ExportData/ExportData")),
  },
  {
    component: lazy(() => import("./containers/Pages/404/404")),
  },
];

function PrivateRoute({ children, ...rest }) {
  let location = useLocation();
  localStorage.setItem("historyPathname",location?.pathname);
  let isLoggedIn = useSelector((state) => state.Auth.accessToken);

  if (isLoggedIn) {
    return <Route {...rest}>{children}</Route>;
  }
  return (
    <Redirect
      to={{
        pathname: PUBLIC_ROUTE.SIGN_IN,
        state: { from: location },
      }}
    />
  );
}

export default function Routes(props) {
  const history = useHistory();
  const whiteLabelingData = useSelector((state) => state.WhiteLabeling);
  const favicon = "../public/favicon.png";
  useEffect(() => {
    document.title = whiteLabelingData.websiteTitle || "Plexus";
    const favi =
      whiteLabelingData.faviconUrl != null
        ? whiteLabelingData.faviconUrl
        : faviIcon;
    changeIconWeb(favi);
  });

  const changeIconWeb = (url) => {
    let link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = url;
    link.sizes = "32x32";

    document.getElementsByTagName("head")[0].appendChild(link);
  };

  return (
    <ErrorBoundary>
      <Suspense fallback={null}>
        <Router>
          <Switch>
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
